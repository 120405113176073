export default {
  password: '密码',
  emailLogin: '邮箱登录',
  noUserName: '没有账号?',
  toRegister: '去注册',
  email: '邮箱',
  enterEmail: '请输入邮箱',
  login: '登录',
  forgetPw: '已有账号，忘记密码?',
  resetPw: '重置密码',
  enterEmailResetPw: '请输入邮箱重置你的密码',
  resetSuc: '重置成功',
  welcomeSignUp: '欢迎注册',
  nickname: '昵称',
  code: '验证码',
  sendCode: '发送验证码',
  SignUpNow: '立即注册',
  enterCodetips: '请输入验证码',
  enterNickname: '请输入昵称',
  enterPw: '请输入密码',
  otherLogin: '其他登录方式',
  hasSend: '我们向邮箱 {email} 发送了一封含有重置密码链接的邮件。请登录网页版邮箱查看，如长时间没有收到邮件，请检查你的垃圾邮件文件夹',
};
