export default {
  namespaced: true,
  state: {
    lang: 'en',
    options: [{
      value: 'en',
      label: 'English',
    }, {
      value: 'zh',
      label: '中文',
    }],
  },
  mutations: {
    updateLang(state, data) {
      state.lang = data;
    },
  },
};
