export default {
  password: 'Password',
  emailLogin: 'Email login',
  noUserName: "Don't have an account?",
  toRegister: 'Sign up',
  email: 'Email',
  enterEmail: 'Please enter your email',
  login: 'Sign in',
  forgetPw: 'forgot your password?',
  resetPw: 'Reset password',
  enterEmailResetPw: 'Please enter your email to reset your password',
  resetSuc: 'Reset successful',
  welcomeSignUp: 'Welcome to sign up',
  nickname: 'Nickname',
  code: 'Code',
  sendCode: 'Send code',
  SignUpNow: 'Sign up now',
  enterCodetips: 'Enter the code',
  enterNickname: 'Enter the nickname',
  enterPw: 'Enter the password',
  otherLogin: 'Other login methods',
  hasSend: "We have sent an email to the address {email} with a password reset link. Please check your webmail to view it. If you haven't received the email after an extended period, please check your spam folder",
};
