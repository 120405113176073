<template>
  <el-dropdown @command="handler" class="drop-down-lang">
  <el-button type="primary">
    {{options.filter(item=>item.value==lang)[0].label}}
    <i class="el-icon-arrow-down el-icon--right"></i>
  </el-button>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item v-for="item in options"
    :key="item.value"
    :command="item.value" >{{item.label}}</el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState({
      lang: (state) => state.langModule.lang,
      options: (state) => state.langModule.options,
    }),
  },
  methods: {
    ...mapMutations('langModule', ['updateLang']),
    handler(val) {
      this.updateLang(val);
      this.$i18n.locale = val;
    },
  },
};
</script>

<style lang="less" scoped>
.drop-down-lang{
  position: fixed;
  right: 20px;
  top: 20px;
}
</style>
