import Vue from 'vue';
import ElementUI from 'element-ui';
import VueParticles from '@/lib/vue-particles';
import 'element-ui/lib/theme-chalk/index.css';
import { retUserNameType } from '@/common/js/get-user-type';
import VueI18n from 'vue-i18n';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import ElementLocale from 'element-ui/lib/locale';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/common/css/common.less'; // 全局样式
import enUS from './locales/en';
import zhCN from './locales/zh';

const dateFormat = require('dateformat');

Vue.prototype.$dateFormat = dateFormat;
Vue.prototype.$retUserNameType = retUserNameType;
Vue.prototype.$requireMsg = [{ required: true, message: '此项为必填项', trigger: 'blur' }];
Vue.config.productionTip = false;
Vue.use(ElementUI, { size: 'mini' });
Vue.use(VueParticles);
Vue.use(VueI18n);
const defaultLocale = 'en';
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  allowComposition: true,
  messages: {
    en: {
      ...enUS,
      ...enLocale,
    },
    zh: {
      ...zhCN,
      ...zhLocale,
    },
  },
});
ElementLocale.i18n((key, value) => i18n.t(key, value));

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
